import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { QuillModule } from 'ngx-quill';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChooseProjectModalComponent } from './components/choose-project-modal/choose-project-modal.component';
import { IonicStorageModule } from '@ionic/storage-angular';

const modules = {
  toolbar: [
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

    ['clean'],                                         // remove formatting button

    ['link']                         // link and image, video
  ]
};

@NgModule({
  declarations: [AppComponent, ChooseProjectModalComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,QRCodeModule,
    IonicStorageModule.forRoot(),
    QuillModule.forRoot({
      modules,
      format: 'json',
      
    }),
    NgCircleProgressModule.forRoot({
      "units": "min",
      "unitsColor": "var(--ion-text-color)", 
      "radius": 37,
      "space": -10,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10,
      "outerStrokeColor": "#4882c2",
      "outerStrokeGradientStopColor": "#53a9ff",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 10,
      "animationDuration": 1000,
      "showSubtitle": false,
      "showBackground": false,
      "clockwise": false,
      "titleColor": "var(--ion-text-color)",
      "responsive": true,
      "startFromZero": false,
      "lazy": false}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
