import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Project } from 'src/app/models/project';
import { PreferencesService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-choose-project-modal',
  templateUrl: './choose-project-modal.component.html',
  styleUrls: ['./choose-project-modal.component.scss'],
})
export class ChooseProjectModalComponent {

  
  constructor(private modalCtrl: ModalController, public preferences: PreferencesService) { 
  }

  new(){
    return this.modalCtrl.dismiss(new Project(), 'new');
  }

  cancel(){
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  selectProject(project: Project){
    this.preferences.touchProject(project.hashId);
    return this.modalCtrl.dismiss(project, 'selected');
  }

}
