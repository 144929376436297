import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  projectList: Project[] = [];

  constructor(private storage: Storage) {
    //this.init();
  }

  async init() {
    console.log("hi");
    await this.storage.create();
    let result = await this.storage.get('my-grouping.info');
    console.log("reading",result);
    if(result != null)
      this.projectList = result;
  }

  getProjectList(){
    return this.projectList;
  }

  async addProject(hashId, title, logoURL, viewMode){
    if(this.isProjectInList(hashId) || hashId == null || hashId == "")
      return;
    let date = new Date();
    let project  = new Project();
    project.hashId = hashId;
    project.title = title;
    project.logoURL = logoURL;
    project.viewMode = viewMode;
    project.lastUsedDate = date;
    this.projectList.push(project);
    this.projectList.sort((a, b) => b.lastUsedDate.getTime() - a.lastUsedDate.getTime());
    await this.writeProjects();
  }

  async removeProject(hashId){
    const i = this.projectList.findIndex(p => p.hashId == hashId);
    if(i > -1){ 
      this.projectList.splice(i,1);
      console.log(this.projectList);
      await this.writeProjects();
      //removeViewSetting
    }
  }

  async setProjectTitleAndLogoURL(hashId, title, logoURL){
    const project = this.projectList.find(p => p.hashId == hashId);
    if(project != undefined){
      project.title = title;
      project.logoURL = logoURL;
      await this.writeProjects();
    }
  }

  async touchProject(hashId){
    const project = this.projectList.find(p => p.hashId == hashId);
    if(project != undefined){
      project.lastUsedDate = new Date();
      this.projectList = this.projectList.sort((a, b) => b.lastUsedDate.getTime() - a.lastUsedDate.getTime());
      await this.writeProjects();
    }
  }

  async getProjectViewSettings(hashId, component){
    const { value } = await this.storage.get("my-grouping.info");
    if(value != null)
      return value;
    return component=="group"?true:false;
  }

  async setProjectViewSettings(hashId, component, value){
    await this.storage.set(hashId + "_" + component,value);
  }

  isProjectInList(hashId){
    return this.projectList.find(p => p.hashId == hashId) != undefined;
  }

  private async removeProjectViewSettings(hashId, component){
    await this.storage.remove(hashId + '_' + component);
  }

  private async writeProjects(){
    console.log("writing", this.projectList);
    await this.storage.set('my-grouping.info',this.projectList);
  }



}
